<template>
  <app-page title="About us"></app-page>
  <app-about></app-about>
</template>

<script>
import AppPage from '@/components/ui/AppPage.vue';
import AppAbout from '@/components/About/AppAbout.vue';

export default {
  components:{
    AppPage,
    AppAbout,
  }
}
</script>

<style>

</style>