<template>
  <page-square></page-square>
</template>

<script>
import PageSquare from '@/pages/PageSquare.vue';
export default {
  components: {
        PageSquare,
    },
}
</script>

<style>

</style>