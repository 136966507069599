<template>
  <app-page title="Contact"></app-page>
  <app-contact></app-contact>
</template>

<script>
import AppPage from '@/components/ui/AppPage.vue';
import AppContact from '@/components/Contact/AppContact.vue';

export default {
  components:{
    AppPage,
    AppContact
  }
}
</script>

<style>

</style>